<template>
  <v-form ref="stepOne" lazy-validation>
    <v-row class="mt-2">
      <v-col cols="12" sm="5">
        <v-text-field
          v-model="info.header.lesson_name"
          :label="$t('app.subjects')"
          dense
          outlined
          readonly
        />
      </v-col>
      <v-col cols="12" sm="2">
        <v-text-field
          v-model="info.header.grade_name"
          :label="$t('app.grade')"
          dense
          outlined
          readonly
        />
      </v-col>
      <v-col cols="12" sm="3">
        <v-text-field
          v-model="info.header.class_name"
          :label="$t('app.class')"
          dense
          outlined
          readonly
        />
      </v-col>
      <v-col cols="12" sm="2">
        <v-text-field
          v-model="info.header.start_year"
          :label="$t('e_test.period')"
          dense
          outlined
          readonly
        />
      </v-col>
      <v-col cols="12" class="font-weight-bold body-1">
        {{ $t("e_test.type_of_test") }}
      </v-col>
      <v-col cols="12" class="mb-5">
        <v-radio-group
          v-model="data.type"
          :disabled="remedy || continuation"
          :column="false"
          :mandatory="true"
        >
          <v-row justify="space-between" no-gutters>
            <v-radio
              v-for="item in typeTest"
              :key="item.id"
              :label="item.name"
              :value="item.id"
              :readonly="!isEdit"
            />
          </v-row>
        </v-radio-group>
      </v-col>
      <v-col cols="12" sm="6">
        <v-text-field
          v-model="data.title"
          :label="$t('e_test.title')"
          :rules="isEdit ? [v => !!v || $t('app.required')] : []"
          :readonly="!isEdit || continuation"
          dense
          outlined
        />
      </v-col>
      <v-col cols="12" sm="3">
        <v-text-field
          v-model="data.duration"
          :label="$t('e_test.table_header.detail_subjects.duration')"
          :rules="
            isEdit
              ? [
                  v => !!v || $t('app.required'),
                  v => v >= 1 || $t('e_test.rule_duration')
                ]
              : []
          "
          :readonly="!isEdit || continuation"
          min="0"
          type="number"
          dense
          outlined
        />
      </v-col>
      <v-col cols="12" sm="3">
        <v-text-field
          v-model="data.chance"
          :label="$t('e_test.chance')"
          :rules="
            isEdit
              ? [
                  v => !!v || $t('app.required'),
                  v => v >= 1 || $t('e_test.rule_chance')
                ]
              : []
          "
          :readonly="!isEdit"
          min="0"
          type="number"
          dense
          outlined
        />
      </v-col>
      <div v-if="remedy || continuation" class="d-flex mb-5">
        <v-col cols="12" sm="3">
          <v-text-field
            v-if="!isEdit"
            :value="formatDate(dateRemedy.startDate)"
            :outlined="!continuation"
            :dense="!continuation"
            :disabled="!continuation"
            :append-icon="continuation ? 'mdi-calendar' : ''"
            hide-details
            readonly
          />
          <DatePicker
            v-else
            :label="$t('e_test.start_date')"
            :disabled="!isEdit"
            :minDate="currentDate"
            :maxDate="maxDate"
            :value="dateRemedy.startDate"
            @getValue="v => (dateRemedy.startDate = v)"
          />
        </v-col>
        <v-col cols="12" sm="3">
          <v-text-field
            v-if="!isEdit"
            :value="formatDate(dateRemedy.endDate)"
            dense
            outlined
            disabled
            hide-details
            readonly
          />
          <DatePicker
            v-else
            :label="$t('e_test.end_date')"
            :disabled="!dateRemedy.startDate"
            :minDate="dateRemedy.startDate"
            :maxDate="maxDate"
            :value="dateRemedy.endDate"
            @getValue="v => (dateRemedy.endDate = v)"
          />
        </v-col>
        <v-col cols="12" sm="3">
          <v-text-field
            v-if="!isEdit"
            :value="dateRemedy.startHours"
            :outlined="!continuation"
            :dense="!continuation"
            :disabled="!continuation"
            hide-details
            readonly
          />
          <TimePicker
            v-else
            :label="$t('e_test.start_hours')"
            :isDisabled="!isEdit"
            :initValue="dateRemedy.startHours"
            @value="v => (dateRemedy.startHours = v)"
          />
        </v-col>
        <v-col cols="12" sm="3">
          <v-text-field
            v-if="!isEdit"
            :value="dateRemedy.endHours"
            dense
            outlined
            disabled
            hide-details
            readonly
          />
          <TimePicker
            v-else
            :label="$t('e_test.end_hours')"
            :isDisabled="!dateRemedy.startHours"
            :min="dateRemedy.startHours"
            :initValue="dateRemedy.endHours"
            @value="v => (dateRemedy.endHours = v)"
          />
        </v-col>
      </div>
      <v-col cols="12">
        <v-textarea
          v-model="data.description"
          :rules="isEdit ? [v => !!v || $t('app.required')] : []"
          :label="$t('e_test.description')"
          :readonly="!isEdit || continuation"
          rows="3"
          auto-grow
          flat
          outlined
        />
      </v-col>
      <v-col v-if="!remedy" cols="12">
        <v-checkbox
          v-model="data.is_private"
          :label="$t('e_test.private_etest')"
        />
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import moment from "moment/moment";
moment.locale("id");

export default {
  props: {
    info: Object,
    data: Object,
    reset: Boolean,
    validate: Boolean,
    edit: Boolean,
    remedy: Boolean,
    dateRemedy: Object,
    isEdit: Boolean,
    continuation: Boolean
  },
  components: {
    TimePicker: () => import("@/components/TimePicker"),
    DatePicker: () => import("@/components/DatePicker")
  },
  watch: {
    reset(value) {
      if (value) {
        this.$refs.stepOne.resetValidation();
      }
    },
    validate(value) {
      if (value) {
        this.$refs.stepOne.validate();
      }
    }
  },
  data() {
    return {
      currentDate: moment().format("YYYY-MM-DD"),
      maxDate: moment()
        .add(6, "months")
        .format("YYYY-MM-DD"),
      typeTest: [
        { id: 1, name: this.$i18n.t("e_test.name_test.daily_test") },
        { id: 2, name: this.$i18n.t("e_test.name_test.homework") },
        { id: 3, name: this.$i18n.t("e_test.name_test.midterm_exam") },
        { id: 4, name: this.$i18n.t("e_test.name_test.semester_exam") }
      ]
    };
  },
  methods: {
    formatDate(date) {
      if (date) return moment(date).format("DD MMM YYYY");
      else return null;
    }
  }
};
</script>
